import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from 'next/link';  
import Typography from '../typography'; 
import useStyles from './style.js';  


export default function Footer() {
  const classes = useStyles(); 
  return (
    <Typography component="footer" className={classes.root}>
      <Container  maxWidth='lg' className={classes.container}>
        
        <Grid container spacing={4}>   
          <Grid item xs={12} sm={6} >
            <Typography variant="h6"  gutterBottom className={classes.title}>
              Contacto
            </Typography>
            <Typography gutterBottom variant="body1" className={classes.text}>
              Teléfono: 301 460 69 80
            </Typography>
            <Typography  gutterBottom variant="body1" className={classes.text}>
              Correo:    direccion@cidu.com.co
            </Typography>
    
              <Grid item className={classes.icons}>
                <a href="https://www.facebook.com/SASCIDU" target="_blank" rel="noopener noreferrer" className={classes.containerIcon}>
                  <img src={"/images/icons/facebook.svg"} className={classes.icon} alt="Fb" width={25} height={25} title={'Ir a Facebook'}  draggable="false"/>
                </a>
                <a href="https://twitter.com/CIDU_SAS" target="_blank" rel="noopener noreferrer" className={classes.containerIcon}> 
                  <img src={"/images/icons/twitter.svg"} className={classes.icon} alt="Tw" width={25} height={25} title={'Ir a Twitter'}  draggable="false"/>
                </a>
                <a href="https://www.instagram.com/sascidu/" target="_blank" rel="noopener noreferrer" className={classes.containerIcon}>
                  <img src={"/images/icons/instagram.svg"} className={classes.icon} alt="In" width={25} height={25} title={'Ir a Instagram'}  draggable="false"/>
                </a>  
                <a href="https://www.linkedin.com/in/jonathan-ortiz-casta%C3%B1o-5938521b5/" target="_blank" rel="noopener noreferrer" className={classes.containerIcon}>
                  <img src={"/images/icons/linkedin.svg"} className={classes.icon} alt="Li" width={25} height={25} title={'Ir a LinkId'}  draggable="false"/>
                </a>  
              </Grid>
          </Grid>

          <Grid item xs={12} sm={6} >
            <Typography variant="h6" gutterBottom className={classes.title}>
              Acerca de 
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="/policy" >Política de Privacidad</Link>
              </li>
              <li className={classes.listItem}>
                <Link href="/terms" >Términos y Condiciones</Link>
              </li> 
              <li className={classes.listItem}>
                <a href="https://blog.cidu.com.co" target="_blank" rel="noopener noreferrer" >Blog</a>
              </li> 
            </ul>
          </Grid>
          
          <Grid item>
            <Typography variant="caption"  className={classes.textSmall}>
              {'Copyright ©'}
              {' '}
              {new Date().getFullYear()}
              {' CIDU - Consultoría de Ingeniería & Desarrollo Urbano S.A.S - Todos los Derechos Reservados'}
            </Typography>
          </Grid>
          
        </Grid>
      </Container>
    </Typography>
  );
};