import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#232529',
    color: 'white',
    fontFamily: 'Century-Gothic',
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    display: 'flex',
  },
  icons: {
    display: 'flex',
    marginTop: '20px',
    textIndent: '15px',
  }, 
  containerIcon:{ 
    marginRight: '0px',
  },
  icon: {
    pointerEvents: 'none',
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
  },
  text:{
    textIndent: '15px',
    fontWeight: 'bold',
    color: '#B3B6B7',
    marginBottom: '10px',
    fontFamily: 'Century-Gothic',
  },
  textSmall:{
    fontFamily: 'Century-Gothic',
    fontWeight: 'bold',
    color: '#B3B6B7',
  },
  title:{
    marginBottom: '15px',
    fontFamily: 'Century-Gothic',
    fontWeight: 'bold',
  },   
  list: {
    textIndent: '15px',
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));
export default useStyles;